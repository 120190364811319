// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav.toolbar {
    background-color: rgb(68, 68, 68);
    color: rgb(255, 136, 136);
    position: sticky;
    height: 3,5vh;
    font-size: 3vh;
    font-family: cursive;
    display: flex;
}

.toolbar .left {
    margin-right: auto;
}

.toolbar .right {
    margin-left: auto;
}

.toolbar button {
    border: 0;
    background-color: rgb(230, 132, 132);
    color: black;
    font-weight: bolder;
    margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/toolbar.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,oCAAoC;IACpC,YAAY;IACZ,mBAAmB;IACnB,WAAW;AACf","sourcesContent":["nav.toolbar {\n    background-color: rgb(68, 68, 68);\n    color: rgb(255, 136, 136);\n    position: sticky;\n    height: 3,5vh;\n    font-size: 3vh;\n    font-family: cursive;\n    display: flex;\n}\n\n.toolbar .left {\n    margin-right: auto;\n}\n\n.toolbar .right {\n    margin-left: auto;\n}\n\n.toolbar button {\n    border: 0;\n    background-color: rgb(230, 132, 132);\n    color: black;\n    font-weight: bolder;\n    margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
