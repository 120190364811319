/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import SentenceList from '../features/sentenceList';
import AddSentence from '../features/addSentence';
import ButtonToolbar from '../features/buttonToolbar';

export default function Sentences() {
    const [showPopup, setShowPopup] = useState(false);
    const arr: Array<any> = [];
    const [sentences, setSentences] = useState(arr);
    const showAdd = function () {
        setShowPopup(!showPopup);
    };
    const closeAdd = function () {
        setShowPopup(false);
    };

    return (
        <div>
            <ButtonToolbar
                showAdd={showAdd}
                buttonTitle={'+ Satz hinzufügen'}
            />
            {showPopup ? (
                <AddSentence
                    sentences={sentences}
                    setSentences={setSentences}
                    close={closeAdd}
                />
            ) : null}
            <SentenceList sentences={sentences} setSentences={setSentences} />
        </div>
    );
}
