// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blockList {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10vh;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/components/blockList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".blockList {\n    list-style: none;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding-bottom: 10vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
