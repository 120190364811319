// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup input {
    display: block;
    margin-bottom: 1%;
    max-width: 90%;
    min-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/features/addSentence.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".popup input {\n    display: block;\n    margin-bottom: 1%;\n    max-width: 90%;\n    min-width: 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
