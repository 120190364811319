/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { getWords } from '../services/words';
import WordList from '../features/wordList';
import AddWord from '../features/addWord';
import ButtonToolbar from '../features/buttonToolbar';

export default function Words() {
    const [showPopup, setShowPopup] = useState(false);
    const arr: Array<any> = [];
    const [words, setWords] = useState(arr);
    useEffect(() => {
        async function loadSentences() {
            setWords(await getWords());
        }

        // eslint-disable-next-line eqeqeq
        if (words.length == 0) {
            loadSentences();
        }
    });
    const showAdd = function () {
        setShowPopup(!showPopup);
    };
    const closeAdd = function () {
        setShowPopup(false);
    };

    return (
        <div>
            <ButtonToolbar
                showAdd={showAdd}
                buttonTitle={'+ Wort hinzufügen'}
            />
            {showPopup ? (
                <AddWord words={words} setWords={setWords} close={closeAdd} />
            ) : null}
            <WordList words={words} />
        </div>
    );
}
