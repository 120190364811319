/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import Popup from '../layouts/Popup';
import '../assets/CSS/features/addSentence.css';
import { saveNewWord } from '../services/words';

export default function AddWord(props: {
    words: any;
    setWords: (arg0: any[]) => any;
    close: () => void;
}) {
    const [word, setWord] = useState('');
    const [description, setDescription] = useState('');

    return (
        <Popup>
            <input
                type="text"
                placeholder="Neues Wort"
                value={word}
                onChange={(e) => setWord(e.target.value)}
            />
            <input
                type="text"
                placeholder="Beschreibung"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <button onClick={props.close}>Abbrechen</button>
            <button
                onClick={() =>
                    saveNewWord(
                        word,
                        description,
                        props.words,
                        props.setWords,
                        props.close,
                    )
                }
            >
                Speichern
            </button>
        </Popup>
    );
}
