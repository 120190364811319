/* eslint-disable @typescript-eslint/no-explicit-any */
import '../assets/CSS/toolbar.css';
import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
} from 'react';

export default function Toolbar(props: {
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}) {
    return <nav className="toolbar">{props.children}</nav>;
}
