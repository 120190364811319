import '../assets/CSS/pages/flames.css';
import background from '../assets/images/heart/background.png';
import center from '../assets/images/heart/center.png';
import picture1 from '../assets/images/heart/1.png';
import picture2 from '../assets/images/heart/2.png';
import picture3 from '../assets/images/heart/3.png';
import picture4 from '../assets/images/heart/4.png';
import picture5 from '../assets/images/heart/5.png';
import picture6 from '../assets/images/heart/6.png';
import picture7 from '../assets/images/heart/7.png';
import picture8 from '../assets/images/heart/8.png';
import picture9 from '../assets/images/heart/9.png';
import picture10 from '../assets/images/heart/10.png';
import picture11 from '../assets/images/heart/11.png';
import picture12 from '../assets/images/heart/12.png';
import picture13 from '../assets/images/heart/13.png';
import picture14 from '../assets/images/heart/14.png';
import picture15 from '../assets/images/heart/15.png';
import picture16 from '../assets/images/heart/16.png';
import picture17 from '../assets/images/heart/17.png';
import '../assets/CSS/pages/heart.css';
import { useState } from 'react';

export default function Heart() {
    const [firstClick, setFirstClick] = useState(0);

    const [images, setImages] = useState(
        [
            { id: 1, image: picture1 },
            { id: 1, image: picture1 },
            { id: 2, image: picture2 },
            { id: 2, image: picture2 },
            { id: 3, image: picture3 },
            { id: 3, image: picture3 },
            { id: 4, image: picture4 },
            { id: 4, image: picture4 },
            { id: 5, image: picture5 },
            { id: 5, image: picture5 },
            { id: 6, image: picture6 },
            { id: 6, image: picture6 },
            { id: 7, image: picture7 },
            { id: 7, image: picture7 },
            { id: 8, image: picture8 },
            { id: 8, image: picture8 },
            { id: 9, image: picture9 },
            { id: 9, image: picture9 },
            { id: 10, image: picture10 },
            { id: 10, image: picture10 },
            { id: 11, image: picture11 },
            { id: 11, image: picture11 },
            { id: 12, image: picture12 },
            { id: 12, image: picture12 },
            { id: 13, image: picture13 },
            { id: 13, image: picture13 },
            { id: 14, image: picture14 },
            { id: 14, image: picture14 },
            { id: 15, image: picture15 },
            { id: 15, image: picture15 },
            { id: 16, image: picture16 },
            { id: 16, image: picture16 },
            { id: 17, image: picture17 },
            { id: 17, image: picture17 },
        ].sort((a, b) => 0.5 - Math.random()),
    );

    async function timeout() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    }

    async function onClick(e: any) {
        const clickedElement = e.target;
        if (firstClick == 0 || firstClick != clickedElement.id) {
            clickedElement.src = images[clickedElement.id - 1].image;

            if (firstClick == 0) {
                setFirstClick(clickedElement.id);
            } else if (firstClick != 0) {
                console.log(images[firstClick - 1].id);
                console.log(images[clickedElement.id - 1].id);
                if (
                    images[firstClick - 1].id ==
                    images[clickedElement.id - 1].id
                ) {
                    setFirstClick(0);
                } else {
                    await timeout();
                    document
                        .getElementById(firstClick.toString())
                        ?.setAttribute('src', background);
                    document
                        .getElementById(clickedElement.id.toString())
                        ?.setAttribute('src', background);
                    setFirstClick(0);
                }
            } else {
                alert('Error, please reload the Page');
            }
        }
    }

    return (
        <div className="heartTable">
            <div className="heartRow">
                <div className="heartCell">
                    <img
                        id="1"
                        className="heartImage small bottom rigth"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="2"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="3"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell"></div>
                <div className="heartCell">
                    <img
                        id="4"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="5"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="6"
                        className="heartImage small bottom left"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div className="heartRow">
                <div className="heartCell">
                    <img
                        id="7"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="8"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="9"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="10"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="11"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="12"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="13"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div className="heartRow">
                <div className="heartCell">
                    <img
                        id="14"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="15"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="16"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="center"
                        className="heartImage"
                        alt="Bild"
                        src={center}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="17"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="18"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="19"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div className="heartRow">
                <div className="heartCell">
                    <img
                        id="20"
                        className="heartImage small top rigth"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="21"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="22"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="23"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="24"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="25"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="26"
                        className="heartImage small top left"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div className="heartRow">
                <div className="heartCell"></div>
                <div className="heartCell">
                    <img
                        id="27"
                        className="heartImage small top rigth"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="28"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="29"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="30"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="31"
                        className="heartImage small top left"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell"></div>
            </div>
            <div className="heartRow">
                <div className="heartCell"></div>
                <div className="heartCell"></div>
                <div className="heartCell">
                    <img
                        id="32"
                        className="heartImage small top rigth"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="33"
                        className="heartImage"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell">
                    <img
                        id="34"
                        className="heartImage small top left"
                        alt="Bild"
                        src={background}
                        onClick={onClick}
                    />
                </div>
                <div className="heartCell"></div>
                <div className="heartCell"></div>
            </div>
        </div>
    );
}
