export async function getWords() {
    const words = await fetch(process.env.REACT_APP_BACKEND_URL + 'words');
    return (await words.json()).words;
}

export function saveNewWord(
    word: string,
    description: string,
    words: any,
    setWords: (arg0: any[]) => any,
    close: { (): void; (): void },
) {
    try {
        fetch(process.env.REACT_APP_BACKEND_URL + 'words', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ word: word, description: description }),
        }).then(async (response) => {
            const answer = await response.json();
            setWords(answer.words);
        });
    } catch (error: any) {
        console.error(`POST Error: ${error.message}`);
    }
    close();
}
