// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    height: 100%;
}

body {
    margin: 0;
    display: flex;
    overflow: hidden;
}

main {
    background: rgb(255,130,130);
    background: linear-gradient(0deg, rgba(255,238,238,1) 33%, rgba(255,130,130,1) 100%);
    height: 90vh;
    width: 100vw;
    position: relative;
    overflow: scroll;
    color: black;
}

p {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/page.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,oFAAoF;IACpF,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,SAAS;AACb","sourcesContent":["html {\n    height: 100%;\n}\n\nbody {\n    margin: 0;\n    display: flex;\n    overflow: hidden;\n}\n\nmain {\n    background: rgb(255,130,130);\n    background: linear-gradient(0deg, rgba(255,238,238,1) 33%, rgba(255,130,130,1) 100%);\n    height: 90vh;\n    width: 100vw;\n    position: relative;\n    overflow: scroll;\n    color: black;\n}\n\np {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
