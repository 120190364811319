// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.popup {
    width: 30%;
    height: 30%;
    min-width: 300px;

    border: 1px solid black;
    border-radius: 20px;

    padding: 10px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgb(236, 236, 236);
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/popup.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;;IAEhB,uBAAuB;IACvB,mBAAmB;;IAEnB,aAAa;;IAEb,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;;IAEhC,oCAAoC;AACxC","sourcesContent":["section.popup {\n    width: 30%;\n    height: 30%;\n    min-width: 300px;\n\n    border: 1px solid black;\n    border-radius: 20px;\n\n    padding: 10px;\n\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    background-color: rgb(236, 236, 236);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
