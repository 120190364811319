import MenuList from '../components/home/menuList';
import MenuItem from '../components/home/menuItem';

import snapchatLogo from '../assets/images/snapchat.png';
import laughingLeo from '../assets/images/Laughing Leo.jpg';
import bitteWas from '../assets/images/BitteWas.png';

export default function HomeList() {
    return (
        <MenuList>
            <MenuItem title="Flammen" image={snapchatLogo} link="/flames" />
            <MenuItem title="Sätze" image={laughingLeo} link="/sentences" />
            <MenuItem title="Wörter" image={bitteWas} link="/words" />
        </MenuList>
    );
}
