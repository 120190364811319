/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
} from 'react';
import '../../assets/CSS/components/menuList.css';

export default function MenuList(props: {
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}) {
    return <ul className="menuList">{props.children}</ul>;
}
