// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 20vw;
    height: 20vw;
    min-width: 120px;
    min-height: 120px;
    max-width: 200px;
    max-height: 200px;
    border: 1px solid;
    border-radius: 3vw;
    background-color: rgba(119, 119, 119, 0.1);
    margin: 5px;
}

.menuItem img {
    height: 75%;
    width: auto;
    margin-top: max(6px, 1vw);
}

.menuItem a {
    height: 100%;
    text-decoration: none;
    color: black;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/assets/CSS/components/menuItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,0CAA0C;IAC1C,WAAW;AACf;;AAEA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".menuItem {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    width: 20vw;\n    height: 20vw;\n    min-width: 120px;\n    min-height: 120px;\n    max-width: 200px;\n    max-height: 200px;\n    border: 1px solid;\n    border-radius: 3vw;\n    background-color: rgba(119, 119, 119, 0.1);\n    margin: 5px;\n}\n\n.menuItem img {\n    height: 75%;\n    width: auto;\n    margin-top: max(6px, 1vw);\n}\n\n.menuItem a {\n    height: 100%;\n    text-decoration: none;\n    color: black;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
