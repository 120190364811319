// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heartTable {
    height: 70vh;
    width: 70vh;

    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 3vh;
}

.heartRow {
    display: flex;
    flex-direction: row;
}

.heartCell {
    width: 10vh;
    height: 10vh;
}

.small {
    width: 5vh !important;
    height: 5vh !important;
}

.bottom {
    margin-top: 5vh;
}

.top {
    margin-bottom: 5vh;
}

.left {
    margin-right: 5vh;
}

.rigth {
    margin-left: 5vh;
}

.heartTable img {
    width: 100%;
    height: 100%;
    background-color: red;
    border: 1px solid;
}
`, "",{"version":3,"sources":["webpack://./src/assets/CSS/pages/heart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".heartTable {\n    height: 70vh;\n    width: 70vh;\n\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    margin-top: 3vh;\n}\n\n.heartRow {\n    display: flex;\n    flex-direction: row;\n}\n\n.heartCell {\n    width: 10vh;\n    height: 10vh;\n}\n\n.small {\n    width: 5vh !important;\n    height: 5vh !important;\n}\n\n.bottom {\n    margin-top: 5vh;\n}\n\n.top {\n    margin-bottom: 5vh;\n}\n\n.left {\n    margin-right: 5vh;\n}\n\n.rigth {\n    margin-left: 5vh;\n}\n\n.heartTable img {\n    width: 100%;\n    height: 100%;\n    background-color: red;\n    border: 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
