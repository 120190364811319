/* eslint-disable @typescript-eslint/no-explicit-any */
import '../assets/CSS/popup.css';
import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
} from 'react';

export default function Popup(props: {
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}) {
    return <section className="popup">{props.children}</section>;
}
