/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
} from 'react';
import Footer from './Footer';
import Header from './Header';
import '../assets/CSS/page.css';

export default function App(props: {
    title:
        | string
        | number
        | boolean
        | ReactPortal
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | null
        | undefined;
    children:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}) {
    return (
        <div>
            <Header title={props.title} />
            <main>{props.children}</main>
            <Footer />
        </div>
    );
}
