// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: rgba(31, 31, 31, 0.9);
    color: rgb(255, 136, 136);
    position: sticky;
    font-size: 5vh;
    font-family: cursive;
}

header a {
    color: rgb(255, 136, 136);
    text-decoration: none;
}

header a:visited {
    color: rgb(255, 136, 136);
    text-decoration: none;
}

header a:active {
    color: rgb(255, 136, 136);
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/header.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["header {\n    background-color: rgba(31, 31, 31, 0.9);\n    color: rgb(255, 136, 136);\n    position: sticky;\n    font-size: 5vh;\n    font-family: cursive;\n}\n\nheader a {\n    color: rgb(255, 136, 136);\n    text-decoration: none;\n}\n\nheader a:visited {\n    color: rgb(255, 136, 136);\n    text-decoration: none;\n}\n\nheader a:active {\n    color: rgb(255, 136, 136);\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
