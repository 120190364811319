/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'react-router-dom';
import '../assets/CSS/header.css';
import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
} from 'react';

export default function Header(props: {
    title:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
}) {
    return (
        <header>
            <Link to={'/'}>{props.title} &#10084;</Link>
        </header>
    );
}
