/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import Popup from '../layouts/Popup';
import { saveNewSentence } from '../services/sentences';
import '../assets/CSS/features/addSentence.css';

export default function AddSentence(props: {
    sentences: any;
    setSentences: (arg0: any[]) => any;
    close: () => void;
}) {
    const [newSentence, setNewSentence] = useState('');

    return (
        <Popup>
            <input
                type="text"
                placeholder="Neuer Satz"
                value={newSentence}
                onChange={(e) => setNewSentence(e.target.value)}
            />
            <button onClick={props.close}>Abbrechen</button>
            <button
                onClick={() =>
                    saveNewSentence(
                        newSentence,
                        props.sentences,
                        props.setSentences,
                        props.close,
                    )
                }
            >
                Speichern
            </button>
        </Popup>
    );
}
