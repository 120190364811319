import Toolbar from '../layouts/Toolbar';

export default function ButtonToolbar(props: {
    showAdd: () => void;
    buttonTitle: string;
}) {
    return (
        <Toolbar>
            <button className="right" onClick={props.showAdd}>
                {props.buttonTitle}
            </button>
        </Toolbar>
    );
}
