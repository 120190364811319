// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    height: 5vh;
    position: absolute; 
    left: 0 ; right: 0; bottom: 0; 
    background-color: rgba(31, 31, 31, 0.9);
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ,EAAE,QAAQ,EAAE,SAAS;IAC7B,uCAAuC;IACvC,YAAY;AAChB","sourcesContent":["footer {\n    height: 5vh;\n    position: absolute; \n    left: 0 ; right: 0; bottom: 0; \n    background-color: rgba(31, 31, 31, 0.9);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
