/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect } from 'react';
import '../assets/CSS/pages/flames.css';
import BlockList from '../components/lists/blockList';
import BlockItem from '../components/lists/blockItem';
import {
    deleteSentence,
    getSentences,
    saiedSentence,
} from '../services/sentences';

export default function SentenceList(props: {
    setSentences: Dispatch<SetStateAction<any[]>>;
    sentences: any[];
}) {
    useEffect(() => {
        async function loadSentences() {
            props.setSentences(await getSentences());
        }

        // eslint-disable-next-line eqeqeq
        if (props.sentences.length == 0) {
            loadSentences();
        }
    });
    return (
        <BlockList>
            {props.sentences.map((s) => {
                return (
                    <BlockItem key={s.id}>
                        <h2>{s.text}</h2>
                        <p>{s.count}</p>
                        <button
                            id={s.id}
                            onClick={(e) => {
                                saiedSentence(
                                    e,
                                    props.sentences,
                                    props.setSentences,
                                );
                            }}
                        >
                            Schon wieder gesagt &#x1F926;
                        </button>
                        <button
                            id={s.id}
                            onClick={(e) => {
                                deleteSentence(
                                    e.target,
                                    props.sentences,
                                    props.setSentences,
                                );
                            }}
                        >
                            Löschen
                        </button>
                    </BlockItem>
                );
            })}
        </BlockList>
    );
}
