// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.flames {
    font-size: 10em;
    text-align: center;
    margin-top: 10%;
}

.flamesButton {
    margin-left: 35%;
    margin-right: 35%;
    width: 30%;
    height: 2em;
    min-width: 100px;

    border-radius: 10px;

    background-color: rgba(31, 31, 31, 0.281);

    text-align: center;

    
}

.flamesButton:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/assets/CSS/pages/flames.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;IACV,WAAW;IACX,gBAAgB;;IAEhB,mBAAmB;;IAEnB,yCAAyC;;IAEzC,kBAAkB;;;AAGtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["p.flames {\n    font-size: 10em;\n    text-align: center;\n    margin-top: 10%;\n}\n\n.flamesButton {\n    margin-left: 35%;\n    margin-right: 35%;\n    width: 30%;\n    height: 2em;\n    min-width: 100px;\n\n    border-radius: 10px;\n\n    background-color: rgba(31, 31, 31, 0.281);\n\n    text-align: center;\n\n    \n}\n\n.flamesButton:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
