/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

export async function getSentences() {
    const sentences = await fetch(
        process.env.REACT_APP_BACKEND_URL + 'sentences',
    );
    return (await sentences.json()).sentences;
}

export function putSentence(newSentence: any) {
    try {
        fetch(process.env.REACT_APP_BACKEND_URL + 'sentences', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sentence: newSentence }),
        });
    } catch (error: any) {
        console.error(`PUT Error: ${error.message}`);
    }
}

export function saiedSentence(
    event: any,
    sentences: any,
    setSentences: Dispatch<SetStateAction<any[]>>,
) {
    const index = sentences.findIndex((e: { id: any }) => {
        // eslint-disable-next-line eqeqeq
        return e.id == event.target.id;
    });

    const newSentence = sentences[index];
    newSentence.count++;

    const copyOfSentences = [...sentences];
    copyOfSentences[index] = newSentence;

    putSentence(newSentence);
    setSentences(copyOfSentences);
}

export function saveNewSentence(
    newSentence: string,
    sentences: any,
    setSentences: (arg0: any[]) => any,
    close: { (): void; (): void },
) {
    try {
        fetch(process.env.REACT_APP_BACKEND_URL + 'sentences', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sentence: newSentence }),
        }).then(async (response) => {
            const answer = await response.json();
            setSentences([...sentences, answer]);
        });
    } catch (error: any) {
        console.error(`POST Error: ${error.message}`);
    }
    close();
}

export function deleteSentence(
    target: any,
    sentences: any[],
    setSentences: Dispatch<SetStateAction<any[]>>,
) {
    try {
        fetch(process.env.REACT_APP_BACKEND_URL + 'sentences', {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: target.id }),
        }).then(async (response) => {
            setSentences(sentences.filter((e: any) => e.id != target.id));
        });
    } catch (error: any) {
        console.error(`POST Error: ${error.message}`);
    }
}
