import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page from './layouts/Page';
import Home from './pages/home';
import Flames from './pages/flames';
import Sentences from './pages/sentences';
import Heart from './pages/heart';
import Words from './pages/words';

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Page title={'Pia`s App'} children={<Home />} />,
        },
        {
            path: '/flames',
            element: <Page title={'I LOVE YOU'} children={<Flames />} />,
        },
        {
            path: '/sentences',
            element: (
                <Page title={'Hat er nicht gesagt'} children={<Sentences />} />
            ),
        },
        {
            path: '/heart',
            element: (
                <Page title={'Ein Jahr mit dir...'} children={<Heart />} />
            ),
        },
        {
            path: '/words',
            element: <Page title={'Bitte Was?'} children={<Words />} />,
        },
    ]);
    return <RouterProvider router={router} />;
}

export default App;
