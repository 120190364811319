/* eslint-disable @typescript-eslint/no-explicit-any */
import '../assets/CSS/pages/flames.css';
import BlockList from '../components/lists/blockList';
import BlockItem from '../components/lists/blockItem';

export default function WordList(props: { words: any[] }) {
    return (
        <BlockList>
            {props.words.map((word) => {
                return (
                    <BlockItem key={word.id}>
                        <h2>{word.word}</h2>
                        <p>{word.description}</p>
                    </BlockItem>
                );
            })}
        </BlockList>
    );
}
