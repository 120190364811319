import { useEffect, useState } from 'react';
import getVersion from '../services/version';
import '../assets/CSS/footer.css';

export default function Footer() {
    const [version, setVersion] = useState('');
    useEffect(() => {
        async function loadVersion() {
            setVersion(await getVersion());
        }

        if (!version) {
            loadVersion();
        }
    });
    return <footer>Version: {version}</footer>;
}
