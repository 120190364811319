import { Link, To } from 'react-router-dom';
import '../../assets/CSS/components/menuItem.css';

export default function MenuItem(props: {
    link: To;
    image: string | undefined;
    title: string;
}) {
    return (
        <li className="menuItem">
            <Link to={props.link}>
                {props.image ? <img src={props.image} alt={''} /> : ''}
                <p>{props.title}</p>
            </Link>
        </li>
    );
}
