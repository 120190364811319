import { useEffect, useState } from 'react';
import '../assets/CSS/pages/flames.css';
import getFlames from '../services/flames';
import { Link } from 'react-router-dom';

export default function Flames() {
    const [flames, setFlames] = useState(0);
    useEffect(() => {
        async function loadFlames() {
            setFlames(await getFlames());
        }

        if (!flames) {
            loadFlames();
        }
    });

    let buttton = null;
    if (flames >= 365) {
        buttton = (
            <div>
                <button
                    className="flamesButton"
                    onClick={() => {
                        document.getElementById('link')?.click();
                    }}
                >
                    Ein Jahr...
                </button>
                <Link id="link" to="/heart" />
            </div>
        );
    }

    return (
        <div>
            <p className="flames">{flames} &#128293;</p>
            {buttton}
        </div>
    );
}
